import React from "react";
import { Services } from "./services";
import { RegistroFotografico } from "./RegistroFotografico";
import { Documentacion } from "./Documentacion";
export const PortalAgro = (props) => {
  return (
    <div id="portalAgro" className="">
      <div className="container">
        <div className="row">
          <div className="col-md-9 "   data-aos="fade-right">
            <div className="content">
              <div className="section-title">
                <h2>PortalAgro</h2>
              </div>
              {props.data ? props.data.paragraphs.map((ph, index) => {
                return <p key={index}>{ph.content}</p>;
              }) : "cargando..."}
            </div>
            <Services data={props.data && props.data.Services} />
          </div>
          <div className="col-md-3"  data-aos="fade-left" >
            {/*
            <div className="preview">
            <iframe style={{border: "none"}} width="360" height="600" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FdFhZXK1JO6XDzzoCDao5iY%2Fapp-2023%3Ftype%3Ddesign%26node-id%3D1930-167%26t%3D6YhZNP1fuGLhjXTR-1%26scaling%3Dscale-down%26page-id%3D1913%253A2%26starting-point-node-id%3D1930%253A167%26show-proto-sidebar%3D1%26mode%3Ddesign" allowFullScreen></iframe>
            </div>
          */}
            <div className="preview">
              <img src="img/preview.gif" alt="" srcSet="" />
              <img src="img/phone.png" alt="" className="preview-frame" />
            </div>
          </div>
        </div>
      </div>
      <RegistroFotografico data={props.data && props.data.RegistroFotografico} />
      <Documentacion data={props.data && props.data.Documentacion}/>
    </div>
  );
};
