import React from "react";
import { GooglePlayButton } from "react-mobile-app-button";

export const Header = (props) => {

  let button = (false) ?  <a
                            href={props.data ? props.data.apk : ""}
                            download={props.data ? props.data.apk : ""}
                            className="btn btn-lg btn-custom"

                          >
                            <i className="fa fa-android" /> DESCARGAR APP
                          </a> 
                          : <div></div>;
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 intro-text">
                <h1 className="" data-aos="fade-right" data-aos-duration="1500">
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>
                <p className="" data-aos="fade-right" data-aos-duration="2000">
                  {" "}
                  {props.data ? props.data.paragraph : "Loading"}
                </p>
                {/*<GooglePlayButton theme="dark" className=""  data-aos="fade-right" data-aos-duration="2000" />*/}
                {button}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
