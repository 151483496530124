import React from "react";
import { MisionVision } from "./misionVision";
import { Objetivos } from "./Objetivos";
import { About } from "./about";

export const Sennova = (props) => {
  return (
    <div id="about-sennova">
      <About data={props.data && props.data.paragraph}/>
      <MisionVision data={props.data && props.data.Mision_vision}/>
      <Objetivos data={props.data && props.data.Objetivos}/>
    </div>
  );
};
