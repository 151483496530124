import React from "react";

export const Entidades = (props) => {
  return (
    <div id="entidades" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Entidades</h2>
        </div>
        <div className="row">
          {
            props.data && props.data.map((entidad)=>{
              return (
                <div className="col col-xs-12 col-md-6" key={entidad.title}>
                  <img src={entidad.img} alt={"logo "+entidad.title} />
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  );
};
