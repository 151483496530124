import React from "react";

export const MisionVision = (props) => {
  return (
    <div id="about-sennova_vision-mision" className="row text-left container">
      <div className="col col-md-6"  data-aos="fade-right"  data-aos-offset="300">
        <div className="item">
          <div className="icon">
            <i className={props.data && props.data.mision.icon}></i>
          </div>
          <div className="content">
            <h3>Misión </h3>
            <span>{props.data && props.data.mision.content}</span>
          </div>
        </div>
      </div>
      <div className="col col-md-6"  data-aos="fade-left" >
        <div className="item">
          <div className="icon">
           <i className={props.data && props.data.vision.icon}></i>  
          </div>
          <div className="content">
            <h3>Visión </h3>
            <span>{props.data && props.data.vision.content}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
