import React from "react";

const navConfig = [
  {
    title: "Inicio",
    path: "#page-top",
  },
  {
    title: "PortalAgro",
    path: "#portalAgro",
    subitems: [
      {
        title: "Producto",
        path: "#portalAgro",
      },
      {
        title: "Registro Fotografico",
        path: "#portalAgro_registroFotografico",
      }
    ]
  },
  {
    title: "SENNOVA",
    path: "#about-sennova",
    subitems: [
      {
        title: "Programa",
        path: "#about-sennova",
      },
      {
        title: "Misión y Visión",
        path: "#about-sennova_vision-mision",
      },
      {
        title: "Historia del proyecto",
        path: "#about-sennova_historia",
      },
      {
        title: "Objetivos",
        path: "#about-sennova_objetivos",
      },
      {
        title: "Equipo",
        path: "#about-sennova_equipo",
      },
    ],
  },
  {
    title: "Entidades",
    path: "#entidades",
  },
  {
    title: "Términos y condiciones",
    path: "#terminos-condiciones",
  },
  {
    title: "Contáctenos",
    path: "#contact",
  },
];

export const Navigation = (props) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand logo page-scroll" href="#page-top">
            {" "}
            <img
              src="img/logo.png"
              className="d-inline-block align-top"
              alt=""
            />
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            {navConfig.map((item) => {
              return (
                <li key={item.title} className="dropdown">
                  <a
                    href={!item.subitems ? item.path : "#"}
                    className="dropdown-toggle page-scroll"
                    type="button"
                    data-toggle={item.subitems ? "dropdown" : "false"}
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {item.title}
                  </a>
                  {item.subitems && (
                    <ul className="dropdown-menu" aria-labelledby={item.title}>
                      {item.subitems.map((subitem) => {
                        return (
                          <li key={subitem.title}>
                            <a href={`${subitem.path}`} className="page-scroll">
                              {subitem.title}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </nav>
  );
};
