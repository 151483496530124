import React from "react";

export const TerminosCondiciones = (props) => {
  return (
    <div id="terminos-condiciones" className="">
      <div className="container">
        <div className="section-title">
          <h2>Términos y condiciones</h2>
          <p>
          Los términos y condiciones de PortalAgro establecen que el uso de la
            app implica la aceptación de las políticas de privacidad y
            protección de datos personales. Además, se especifica que la
            información proporcionada en los catálogos es meramente informativa
            y no constituye una oferta comercial. Asimismo, la aplicación no sé
            responsabiliza por posibles errores en la descripción o
            disponibilidad de los productos.
          </p>
          <p>
            Puede descargar nuestros términos y condiciones en formato PDF por
            medio del enlace que se muestra debajo.
          </p>
        </div>
        <div className="row">
          <div className="col">
              <a href={props.data} download={props.data} className="btn btn-lg btn-custom">
                <i className="fa fa-file-pdf-o"/> DESCARGAR PDF
              </a>
          </div>
        </div>
      </div>
    </div>
  );
};
