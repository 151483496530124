import React from "react";

export const Objetivos = (props) => {
  return (
    <div className="container" id="about-sennova_objetivos">
      <h3>Nuestros objetivos</h3>
      <div className="row text-center">
        {props.data &&
          props.data.map((objetivo, index) => {
            return (
              <div 
                className="col-md-4" key={index}
                data-aos="fade-up"
                data-aos-duration={1000*(index+1)}
                data-aos-offset="700"
              >
                <div className="panel panel-default objetivos-item" >
                  <div className="panel-title">
                    <h2>{index + 1}</h2>
                  </div>
                  <div className="panel-body">
                    <img src={objetivo.img} alt={objetivo.Nombre} />
                    {/*<i className={objetivo.icon} alt="" />*/}
                  </div>
                  <div className="panel-footer text-left">
                    <h3>{objetivo.Nombre}</h3>
                    <p className="panel-text">{objetivo.Descripcion}</p>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
