import React from "react";

export const Services = (props) => {
  return (
    <div id="portalAgro_servicios" className="">
      <div className="">
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-sm-6 col-xs-12">
                  {" "}
                  <i className={`${d.icon} text-center`}></i>
                  <div className="service-desc text-left">
                    <h3 >{d.name}</h3>
                    <ul >
                      {d.items.map((item, index) => (
                        <li key={index}>{item.content}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
