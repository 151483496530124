import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import AOS from "aos";
import { Header } from "./components/header";
import { Sennova } from "./components/Sennova/Sennova";
import { TerminosCondiciones } from "./components/TerminosCondiciones";
import { Contact } from "./components/contact";
import { Entidades } from "./components/Entidades";
import { PortalAgro } from "./components/PortalAgro/PortalAgro";

import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', { // SmoothScroll
  speed: 1000,
  speedAsDuration: true,
});

AOS.init() // init AOS animations

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Navigation />
      <Header data={landingPageData.Header} />
      <PortalAgro data={landingPageData.PortalAgro} />
      <Sennova data={landingPageData.Sennova} />
      <Entidades data={landingPageData.Entidades} />
      <TerminosCondiciones data={landingPageData.TerminosCondiciones} />
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default App;
