export const About = (props) =>{
    return (
        <div className="container" id="about-sennova_about">
        <div className="row">
          <div className="col-xs-12 col-md-6"  data-aos="fade-right" data-aos-offset="500">
            {" "}
            <img src="img/about.png" className="img-responsive about-img" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6"  data-aos="fade-left"  data-aos-offset="500">
            <div className="about-text">
              <h2>SENNOVA</h2>
              <p>{props.data ? props.data : "loading..."}</p>
            </div>
          </div>
        </div>
      </div>
    )
}